button {
    border: 2px solid rgba($orange, 1);
    border-radius: 0px;
    background-color: transparent;
    color: rgba($dark, 1);
    width: calc(100% - 20px);
    padding: 10px 0px;
    margin: 0px 10px;
    font-family: $content;
    font-size: calc(var(--font-size) - 4px);
    outline: none;
    transition: all 0.2s ease-in-out, font-size 0s;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
        border: 2px solid rgba($grey, 1); } }
