.nav {
    width: 25px;
    position: fixed;
    right: 30px;
    top: 50%;
    transform: translate(-50%, -50%);

    .content {
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
        text-align: center;
        overflow: initial;

        .scroll_monitor {
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 12px;
            background-color: rgba($orange, 1);
            height: 350px;
            width: 2px; }

        .heading {
            font-family: $content;
            font-size: 30px;
            position: absolute;
            transform: translate(-50%, -50%);
            top: -40px;
            left: 50%;
            border-bottom: 2px solid rgba($orange, 1); }

        .item_point {
            width: 20px;
            height: 20px;
            border: 2px solid rgba($orange, 1);
            border-radius: 50%;
            position: relative;
            cursor: pointer;
            background-color: rgba($white, 1);

            .blob {
                position: absolute;
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background-color: rgba($grey, 1);
                visibility: hidden; }

            .item {
                margin-right: 10px;
                position: absolute;
                top: 0px;
                right: 20px;
                width: 200px;
                text-align: right;
                font-family: $content; }

            .item2 {
                margin-left: 10px;
                position: absolute;
                top: 0px;
                left: 20px;
                width: 200px;
                text-align: left;
                font-family: $content; } }

        .connect {
            height: 50px;
            width: 2px;
            // background-color: rgba($orange, 1)
            margin: 0px 11px; } } }

.project_nav {
    .content {
        .scroll_monitor {
            height: 440px; } } }
