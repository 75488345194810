@media (max-width: 1050px) {
    .nav {
        .content {
            .item_point {
                .item {
                    display: none; } } } }

    .main {
        width: 100%;

        .content {

            .header {
                text-align: center; }

            .scroll_button {
                .tooltip {
                    display: none; } } } } }

@media (max-width: 940px) {
    .nav {
        bottom: 20px;
        top: unset;
        transform: rotate(-90deg);
        left: calc(50% - 13px);
        display: none; }

    .main {
        .content {
            padding-bottom: 70px;

            .footer {
                display: none; } } } }

@media (max-width: 640px) {
    .main {
        .content {
            .scroll_button {
 } } } }                // bottom: 60px

@media (max-width: 592px) {
    .main {
        .content {
            .header {
                .name_box {
                    margin-left: 0px; }

                .mobile {
                    display: block; } }

            .link_to_other {
                bottom: -30px;
                left: calc(50% - 140px); } } } }

@media (max-width: 500px) {
    .nav {
        .content {
            .connect {
                height: 30px; }

            .scroll_monitor {
                height: 260px; } } }

    .main {
        .content {
            .footer {
                width: 320px; } } } }

@supports (overflow:-webkit-marquee) and (justify-content:inherit) {
    .main {
        .content {
            margin-bottom: 100px; } } }
