@import "components/_variables";
@import "components/_scrollbar";

body {
    background-color: rgba($white, 1);
    margin: 0px;

    .body {
        overflow: hidden;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        transform: rotate($rotation);

        a {
            color: rgba($orange, 1);
            text-decoration: none;
            outline: none; } } }

@import "components/cv/main/_main";
@import "components/cv/navigation/_nav";
@import "components/cv/sections/_sections";
@import "components/cv/sections/technical/_rating";
@import "components/_buttons";

@import "components/landing/main/_landing";

@import "components/_slick_override";
@import "components/cv/main/_responsive";
@import "components/parallax/_parallax";

@import "components/menu/_slide_menu";

@import "components/lost/_lost";
