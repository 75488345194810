.main {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    text-align: center;
    height: 100vh;
    overflow: overlay;
    position: relative;
    overflow-x: hidden;
    color: rgba($black, 1);

    .content {
        width: 800px;
        max-width: calc(100% - 15px);
        padding-right: 15px;
        display: inline-block;
        text-align: left;
        position: relative;
        overflow: hidden;
        margin-right: 200px;
        margin-left: 15px;

        #fade {
            transition: all 0.4s ease-in-out, color 0s; }

        #cv, #projects, #landing, #lost {
            transition: all 0.4s ease-in-out, color 0s;

            .box {
                width: 100%;
                height: 100%;
                border-radius: 5px;
                background-color: rgba($teal, 0.1);
                z-index: -1; }

            .up {
                font-size: 50px;
                line-height: 50px;
                margin-top: -2px; } }

        .scroll_button {
            width: 50px;
            height: 48px;
            border-radius: 50%;
            border: 2px solid rgba($orange, 1);
            position: fixed;
            bottom: 20px;
            right: 20px;
            color: rgba($grey, 1);
            font-size: 40px;
            text-align: center;
            padding-top: 2px;
            background-color: rgba($white, 1);
            font-family: $content;
            cursor: pointer;

            .tooltip {
                position: absolute;
                transform: translate(-50%, -50%);
                top: 50%;
                left: -60px;
                text-align: right;
                font-size: 16px;
                width: 96px;
                color: rgba($black, 1); } }

        .footer {
            display: none;
            background-color: rgba(white, 0.9);
            position: fixed;
            bottom: 15px;
            left: 50%;
            transform: translate(-50%, 0px);
            height: 35px;
            width: 420px;
            border-radius: 20px; }

        .header {
            margin-top: 30px;
            margin-bottom: 50px;
            text-align: center;

            .background_shapes {
                position: fixed;
                top: 0px;
                left: 0px; }

            .image_block {
                display: inline-block;
                vertical-align: middle;
                position: relative;

                .profile {
                    width: 250px;
                    border-radius: 50%; }

                .glasses {
                    position: absolute;
                    top: 75px;
                    left: 60px;
                    width: 60px;
                    animation: none; } }

            .mobile {
                display: none; }

            .name_box {
                display: inline-block;
                vertical-align: middle;
                margin-left: 20px;
                font-family: $content;
                text-align: center;
                position: relative;

                .name {
                    border-bottom: 2px solid rgba($orange, 1);
                    padding: 10px 0px;
                    position: relative;
                    font-size: 50px;
                    line-height: 50px;

                    .description {
                        position: absolute;
                        bottom: 0px;
                        right: 2px;
                        letter-spacing: 1.24px;
                        display: block;
                        font-size: 16px;
                        text-align: left;
                        color: rgba($grey, 1);
                        line-height: 16px; } }

                .icon {
                    display: inline-block;
                    margin: 10px 5px;
                    font-size: 35px;
                    color: black;
                    border-radius: 50%;
                    height: 38px;
                    background-color: rgba(white, 0.6);

                    .contact_img {
                        width: 40px;
                        margin: -1px; }

                    &:hover {
                        background-color: rgba($teal, 0.1); } }

                .link_to_other {
                    position: absolute;
                    transform: translate(0%, 0%);
                    bottom: -40px;
                    left: 0px;
                    font-family: $content;

                    span {
                        cursor: pointer;
                        color: rgba($orange, 1);

                        .arrow {
                            font-size: 24px;
                            display: inline-block;
                            vertical-align: middle;
                            line-height: 24px; } } } }

            &:hover {
                .image_block {
                    .glasses {
                        animation: shake 0.2s infinite; } } } } } }

@keyframes shake {
    0%, 50%, 100% {
        transform: rotate(0deg); }

    25% {
        transform: rotate(-10deg); }

    75% {
        transform: rotate(10deg); } }
