/* width */
::-webkit-scrollbar {
    width: 10px; }

/* Track */
::-webkit-scrollbar-track {
    background: transparent; }

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba($grey, 1); }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba($grey, 1); }

@media (max-width: 940px) {
    ::-webkit-scrollbar {
        width: 3px; } }
