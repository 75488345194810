.gear {
    position: fixed;
    z-index: 99;
    top: 20px;
    color: rgba($black, 1);
    font-size: 40px;
    cursor: pointer;

    &.right {
        right: 20px; }

    &.left {
        left: 20px; }

    .tooltip {
        font-size: 16px;
        font-family: $content;
        display: inline-block;
        vertical-align: super;

        &.right {
            margin-right: 5px; }

        &.left {
            margin-left: 5px; } } }

.slide_menu {
    display: none;
    transform: var(--menu-closed);
    transition: transform 0.2s ease-in-out;
    max-width: calc(100% - 70px);
    width: 400px;
    background-color: rgba($white, 1);
    height: 100vh;
    position: fixed;
    z-index: 100;

    &.right {
        right: 0px;
        border-left: 2px solid rgba($black, 1); }

    &.left {
        left: 0px;
        border-right: 2px solid rgba($black, 1); }

    .scroller {
        height: calc(100% - 15px);
        overflow: scroll;
        padding: 15px;
        margin-right: -1px;

        .contact {
            text-align: center;

            .icon {
                display: inline-block;
                margin: 10px 5px;
                font-size: 35px;
                color: black;
                border-radius: 50%;
                height: calc(var(--font-size) + 22px);
                background-color: rgba(white, 0.6);

                .contact_img {
                    width: calc(var(--font-size) + 24px);
                    margin: -1px; }

                &:hover {
                    background-color: rgba($teal, 0.1); } } }

        .link_to_other {
            font-family: $content;
            color: rgba($black, 1);
            text-align: center;

            span {
                cursor: pointer;
                color: rgba($orange, 1);

                .arrow {
                    font-size: 24px;
                    display: inline-block;
                    vertical-align: middle;
                    line-height: 24px; } } }

        .X {
            color: rgba($black, 1);
            font-size: 45px;
            margin: -13px;
            cursor: pointer; }

        .menu_heading {
            font-family: $content;
            font-size: calc(var(--font-size));
            margin-bottom: 15px;
            margin-top: 15px;
            color: rgba($black, 1); }

        button {
            margin: 0px;
            width: 100%;
            margin-bottom: 5px;

            &.active {
                background-color: rgba($teal, 0.3); } } } }

@media (max-width: 1250px) {
    .gear {
        .tooltip {
            &.left {
                display: none; } } } }

@media (max-width: 1050px) {
    .gear {
        .tooltip {
            &.right {
                display: none; } } } }
