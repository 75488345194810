.rating {
    height: 20px;
    width: 100px;
    background-color: rgba($orange, 0.4);
    position: relative;
    display: inline-block;
    vertical-align: middle;

    .filler {
        background-color: rgba($orange, 1);
        height: 20px;
        position: absolute; }

    .rating_text {
        color: rgba($dark, 1);
        margin-left: 5px;
        font-family: $content;
        position: absolute;
        top: 1px; } }
