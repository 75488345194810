#lost {
    height: 100vh;
    width: 100%;

    #fade {
        height: 100vh;
        width: 100%;

        .section {
            // width: 100%
            // height: 100%
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            box-shadow: none;
            overflow: hidden;
            padding: 15px;
            width: calc(100% - 30px);

            .heading {
                max-width: calc(100% - 30px);
                font-size: calc(var(--font-size) + 34px);
                line-height: calc(var(--font-size) + 36px); }

            .content-lost {
                color: rgba($orange, 1);
                font-size: calc(var(--font-size) + 8px);
                line-height: calc(var(--font-size) + 10px);
                font-family: $content;
                text-align: center; }

            .gif {
                max-width: calc(100% - 30px); }

            .link_to_other {
                font-family: $content;

                span {
                    cursor: pointer;
                    color: rgba($orange, 1);

                    .arrow {
                        font-size: 24px;
                        display: inline-block;
                        vertical-align: middle;
                        line-height: 24px; } } }

            .contact {
                text-align: center;
                margin-top: 15px;

                .icon {
                    display: inline-block;
                    margin: 10px 5px;
                    font-size: 35px;
                    color: black;
                    border-radius: 50%;
                    height: calc(var(--font-size) + 22px);
                    background-color: rgba(white, 0.6);

                    .contact_img {
                        width: calc(var(--font-size) + 24px);
                        margin: -1px; }

                    &:hover {
                        background-color: rgba($teal, 0.1); } } }

            .link_btn {
                display: inline-block;
                width: 200px;
                font-size: 30px;
                line-height: 30px;
                font-family: $content;
                background-color: rgba($white, 1);
                border-bottom: 2px solid rgba($orange, 1);
                padding: 10px;
                margin: 0px 10px;
                margin-top: 10px;
                cursor: pointer; } } } }
