.section {
    margin-bottom: 50px;
    box-shadow: 2px 0px 0px rgba($orange, 1) inset;
    position: relative;
    overflow: hidden;
    transition: all 0.4s ease-in-out, color 0s;

    .accordion {
        max-height: 0px;
        overflow: hidden;
        transition: all 0.2s ease-in-out; }

    // .dropper, .dropper_projects
    //     border: 2px solid rgba($orange, 1)
    //     width: 30px
    //     height: 99px
    //     position: absolute
    //     top: 39px
    //     left: -33px
    //     transform: rotate(30deg)
    //     background-color: rgba($white, 1)
    //     padding: 2px

    // .dropper_projects
    //     top: 74px

    .heading {
        font-size: calc(var(--font-size) + 14px);
        // line-height: 35px
        font-family: $content;
        border-bottom: 2px solid rgba($orange, 1);
        padding: 10px;
        width: 75%;
        padding-left: 30px;
        position: relative;

        .dropper {
            position: absolute;
            bottom: 20px;
            left: -10px;
            width: 30px;
            height: 99px;
            transform: rotate(30deg) translate(100%, 100%);
            background-color: rgba($white, 1);
            padding: 2px;
            border: 2px solid rgba($orange, 1); }

        .heading_text {
            text-overflow: ellipsis;
            width: calc(100% - 82px);
            margin-left: 15px;
            display: inline-block;
            overflow: hidden;
            vertical-align: middle;
            white-space: nowrap;
 }            // height: 66px

        .logo {
            display: inline-block;
            vertical-align: middle;
            width: 67px;
            height: 65px; } }

    .sub_section {
        padding: 0px 10px;
        margin-top: 40px;

        .bio_text {
            font-family: $content;
            padding-left: 20px;
            font-size: var(--font-size); }

        .logo {
            display: inline-block;
            vertical-align: middle;
            width: 70px; }

        .sub_content {
            display: inline-block;
            vertical-align: middle;
            margin-left: 20px;
            width: calc(100% - 90px);

            .sub_heading {
                font-family: $content;
                font-size: 20px;
                font-size: calc(var(--font-size) + 4px); }

            .date {
                font-family: $content;
                font-size: calc(var(--font-size) - 4px);
                margin-bottom: 20px;
                color: rgba($grey, 1); }

            .text {
                font-family: $content;
                line-height: calc(var(--font-size) + 8px);
                font-size: $content_size; }

            .skill {
                font-family: $content;
                display: inline-block;
                width: calc(100% - 110px);
                margin-right: 10px;
                font-size: var(--font-size);
                vertical-align: middle; } } }

    hr {
        border: none;
        border-top: 1px solid rgba($teal, 1);
        height: 0px;
        margin-top: 40px;
        margin-bottom: 20px;
        width: 70%; } }

#projects {
    .section {
        .sub_section {
            .sub_content {
                width: calc(100% - 20px);

                .slideshow {

                    .img_wrap {
                        text-align: center;

                        .project_image {
                            height: 150px;
                            display: inline-block;
 } } } } } } }                            // margin: 0px 10px
