body {
    .main {
        .content {
            #cv, #projects, #landing, #lost {
                .background_mobile {
                    display: none; } } } } }

@media (max-width: 830px) {
    body {
        .main {
            .content {
                #cv, #projects, #landing, #lost {
                    .background_parallax {
                        display: none; }

                    .background_mobile {
                        display: block; } } } } } }
