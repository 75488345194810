// $content: "Amazon Ember","Helvetica Neue","Helvetica","Arial",sans-serif
$heading: var(--heading);
$content: var(--content);

$content_size: var(--font-size);

$orange: var(--orange);
$teal: var(--teal);
$black: var(--black);
$grey: var(--grey);
$medium: var(--medium);
$light: var(--light);
$white: var(--white);
$dark: var(--dark);

$rotation: var(--rotation);
