.slick-next:before, .slick-prev:before {
    color: rgba($medium, 1);
    font-size: 32px; }

.slick-next:hover, .slick-prev:hover {
    border: none;
    outline: none; }

.slick-next {
    // right: 0px
    width: 32px;
    height: 32px; }

.slick-prev {
    // left: 0px
    z-index: 1;
    width: 32px;
    height: 32px; }

.slick-slider {
    width: calc(100% - 30px);
    display: inline-block; }

@media (min-width: 900px) {
    #projects {
        .section {
            .sub_section {
                .sub_content {
                    .slideshow {
                        .img_wrap {
                            .project_image {
                                height: 300px; } } } } } } } }

@media (min-width: 700px) {
    #projects {
        .section {
            .sub_section {
                .sub_content {
                    .slideshow {
                        .img_wrap {
                            .project_image {
                                height: 250px; } } } } } } } }

@media (min-width: 600px) and (max-width: 700px) {
    #projects {
        .section {
            .sub_section {
                .sub_content {
                    .slideshow {
                        .img_wrap {
                            .project_image {
                                height: 200px; } } } } } } } }

@media (max-width: 460px) {
    #projects {
        .section {
            .sub_section {
                .sub_content {
                    .slideshow {
                        .img_wrap {
                            .project_image {
                                height: 100px; } } } } } } } }
