.landing_wrap {
    overflow: hidden;

    .content {
        position: initial;
        width: 100%;
        height: 100vh;
        text-align: center;
        overflow: visible;

        .landing {
            // position: absolute
            // transform: translate(-50%, -50%)
            // top: 50%
            // left: 50%
            // width: 800px
            // max-width: calc(100% - 30px)
            // display: inline-block
            // transition: 0.2s all ease-in-out

            .background_wrapper {
                width: 800px;
                max-width: calc(100% - 15px);
                padding-right: 15px;
                display: inline-block;
                text-align: left;
                position: relative;
                margin-right: 200px;
                margin-left: 0px;
                height: 100vh;
                // margin-top: -248px

                &:nth-child(3) {
                    position: absolute;
                    top: 0px;
                    left: 15px; } }

            .header {
                position: relative;
                transform: translate(-50%, 0%) scale(1.4);
                top: 100px;
                left: 50%;
                text-align: center;
                margin: 0px;
                transition: all 0.4s ease-in-out, color 0s;
                width: 800px;
                max-width: calc(100%);
                height: 0px;
                z-index: 1; }

            .header_after {
                transform: translate(calc(-50% - 108px), 0%);
                position: relative;
                transition: 0.4s all ease-in-out;
                top: 30px; }

            .welcome {
                text-align: center;
                font-size: 30px;
                line-height: 30px;
                font-family: $content;
                position: absolute;
                transform: translate(-50%, -50%);
                top: calc(50% + 150px);
                left: 50%;
                width: 800px;
                max-width: calc(100% - 30px);
                transition: all 0.4s ease-in-out, color 0s;

                .links {
                    font-size: 18px;
                    margin-top: 20px; }

                .link_btn {
                    display: inline-block;
                    width: 200px;
                    // border: 2px solid rgba($light, 1)
                    background-color: rgba($white, 1);
                    border-bottom: 2px solid rgba($orange, 1);
                    padding: 10px;
                    margin: 0px 10px;
                    margin-top: 10px;
                    cursor: pointer; } } } } }

// .landing_wrap .main
//     height: auto

@media (max-width: 1130px) {
    .landing_wrap {
        .content {
            .landing {
                .header {
                    transform: translate(-50%, 0%) scale(1.2); }

                .header_after {
                    transform: translate(calc(-50% - ((100vw - 830px) / 2) - 7px), 0%);
                    top: 30px; }

                .welcome {
                    top: calc(50% + 150px); } } } } }

@media (max-width: 830px) {
    .landing_wrap {
        .content {
            .landing {
                .background_wrapper {
                    margin-right: -3px; }

                .header {
                    transform: translate(-50%, 0%) scale(1.2); }

                .header_after {
                    transform: translate(calc(-50% - 0px), 0%);
                    top: 30px; }

                .welcome {
                    top: calc(50% + 150px); } } } } }

@media (max-width: 730px) {
    .landing_wrap {
        .content {
            .landing {
                .header {
                    transform: translate(-50%, 0%) scale(1); }

                .header_after {
                    transform: translate(-50%, 0%);
                    top: 30px; }

                .welcome {
                    top: calc(50% + 100px); } } } } }

@media (max-width: 622px) {
    .landing_wrap {
        .content {
            .landing {
                .welcome {
                    top: calc(50% + 150px); } } } } }

@media (max-width: 593px) {
    .landing_wrap {
        .content {
            .landing {
                .header {
                    top: 50px; }

                .header_after {
                    top: 30px; } } } } }

@media (max-width: 547px) {
    .landing_wrap {
        .content {
            .landing {
                .header {
                    top: 70px; }

                .header_after {
                    top: 30px; }

                .welcome {
                    top: calc(50% + 200px); } } } } }

@media (min-height: 900px) {
    .landing_wrap {
        .content {
            .landing {
                .header {
                    top: 150px; }

                .header_after {
                    top: 30px; } } } } }

@media (min-height: 900px) and (min-width: 592px) {
    .landing_wrap {
        .content {
            .landing {
                .header {
                    top: 250px; }

                .header_after {
                    top: 30px; } } } } }

@media (max-height: 624px) and (max-width: 517px) {
    .landing_wrap {
        .content {
            .landing {
                .header {
                    top: 50px; }

                .header_after {
                    top: 30px; } } } } }

@media (max-height: 590px) and (max-width: 517px) {
    .landing_wrap {
        .content {
            .landing {
                .header {
                    top: 30px; }

                .header_after {
                    top: 30px; } } } } }

@media (max-height: 624px) and (max-width: 571px) {
    .landing_wrap {
        .content {
            .landing {
                .welcome {
                    top: calc(50% + 220px); } } } } }

@media (max-height: 549px) and (max-width: 571px) {
    .landing_wrap {
        .content {
            .landing {
                .welcome {
                    top: calc(50% + 200px); }

                .header {
                    top: 30px; }

                .header_after {
                    top: 30px; } } } } }

@media (max-height: 549px) and (min-width: 592px) {
    .landing_wrap {
        .content {
            .landing {
                .welcome {
                    top: calc(50% + 200px); }

                .header {
                    top: 30px; }

                .header_after {
                    top: 30px; } } } } }
